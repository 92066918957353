/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex } from "./Elements"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="wrapper">
        <Header siteTitle={data.site.siteMetadata.title} />
        <main
          style={{
            margin: `0 auto`,
            width: "100%",
            maxWidth: 800,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
            flex: 1,
            marginTop: "1rem",
          }}
        >
          {children}
        </main>
        <Flex
          as="footer"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          py="2rem"
          style={{
            margin: `0 auto`,
            width: "100%",
            borderTop: "1px dashed #d0d0d0",
          }}
        >
          <Flex>
            <Box mx="1rem">
              <h3>JSON Hosting</h3>
            </Box>
            <Box mx="1rem">
              <h3>JSON Storage</h3>
            </Box>
            <Box mx="1rem">
              <h3>myjson.com alternative</h3>
            </Box>
          </Flex>
          <Box>
            © {new Date().getFullYear()}, Made by
            {` `}
            <a
              href="https://creotip.io/"
              style={{ marginLeft: "5px", color: "#495057" }}
            >
              {" "}
              Creotip
            </a>
          </Box>
        </Flex>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
