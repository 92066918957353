import React, { useState } from "react"
import styled from "styled-components"
import {
  compose,
  color,
  space,
  typography,
  layout,
  textAlign,
  fontSize,
  flexbox,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
} from "styled-system"

export const Box = styled("div")(
  {
    boxSizing: "border-box",
  },
  space,
  layout,
  flexbox
)

export const Flex = styled("div")(
  {
    display: "flex",
  },
  space,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent
)

export const Text = styled.span`
    ${textAlign}
    ${fontSize}
    ${space}
    ${color}
  `

export const Row = styled.div`
  ${space}
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
`
export const StyledBox = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
`
